<template>
  <section>
    <div class="content-header">
      <h2>Works cited </h2>
    </div>
    <div class="content-wrapper">
      <p>Here are the resources cited throughout this section of the toolkit, in the order in which they appeared. </p>
      <ul>
        <li class="pb-3">Kirmayer LJ, Dandeneau S, Marshall E, Phillips MK, Williamson KJ. Rethinking Resilience from Indigenous Perspectives. <em>The Canadian Journal of Psychiatry</em>. 2011;56(2):84-91. doi: <a href="https://doi.org/10.1177/070674371105600203" target="_blank">10.1177/070674371105600203</a></li>
        <li class="pb-3">Ezekiel, F. (2021). <em>Mental Health and Academic Performance in Postsecondary Education: Sociodemographic Risk Factors and Links to Childhood Adversity</em>, PhD Thesis, University of Toronto, Toronto, Canada.</li>
        <li class="pb-3">Learning Specialists Association of Canada. (2021, September). <em>LSAC September 2021 eKeynote: Rick Ezekiel</em>. LSAC.</li>
        <li class="pb-3">Meyer, A., Rose, D.H., & Gordon, D. (2014). <em>Universal design for learning: Theory and Practice</em>. Wakefield, MA: CAST Professional Publishing.</li>
        <li class="pb-3">Cheng, D. X. (2004). Students’ sense of campus community: What it means, and what to do about it. NAPSA journal, 41(2), 216-234. <a href="https://www.tandfonline.com/doi/abs/10.2202/1949-6605.1331" target="_blank">https://www.tandfonline.com/doi/abs/10.2202/1949-6605.1331</a></li>
        <li class="pb-3">Cooper, R. (2009). Constructing belonging in a diverse campus community. <em>Journal of College and Character, 10(1), 1-10</em>. <a href="https://www.tandfonline.com/doi/abs/10.2202/1940-1639.1085" target="_blank">https://www.tandfonline.com/doi/abs/10.2202/1940-1639.1085</a></li>
        <li class="pb-3">Gurin, P., Nagda, B. R. A., & Zúñiga, X. (2013). <em>Dialogue across difference: Practice, theory, and research on intergroup dialogue</em>. New York, NY: Russell Sage Foundation. <br><a href="https://muse.jhu.edu/book/22179" target="_blank">muse.jhu.edu/book/22179</a></li>
        <li class="pb-3">Good, C., Rattan, A., & Dweck, C. S. (2012). Why do women opt out? Sense of belonging and women's representation in mathematics. <em>Journal of Personality and Social Psychology, 102</em>(4), 700–717. <a href="https://doi.org/10.1037/a0026659" target="_blank">doi.org/10.1037/a0026659</a></li>
        <li class="pb-3">Hausmann, L.R.M., Ye, F., Schofield, J.W. <em>et al</em>. Sense of Belonging and Persistence in White and African-American First-Year Students. <em>Research in Higher Education</em> 50, 649–669 (2009). <a href="https://doi.org/10.1007/s11162-009-9137-8" target="_blank">doi.org/10.1007/s11162-009-9137-8</a></li>
        <li class="pb-3">Ingram, D. (2012). College students’ sense of belonging: Dimensions and correlates. Dissertation. Retrieved from: <a href="https://stacks.stanford.edu/file/druid:rd771tq2209/Dabney%20Ingram%20dissertation_submitted%20to%20Stanford_060512-augmented.pdf" target="_blank">stacks.stanford.edu</a></li>
        <li class="pb-3">Meeuwisse, M., Severiens, S.E. & Born, M.P. Learning Environment, Interaction, Sense of Belonging and Study Success in Ethnically Diverse Student Groups. <em>Research in Higher Education</em> 51, 528–545 (2010). <br><a href="https://doi.org/10.1007/s11162-010-9168-1" target="_blank">doi.org/10.1007/s11162-010-9168-1</a> </li>
        <li>Walton, G.M., & Cohen, G. L. (2011). A brief social-belonging intervention improves academic and health outcomes of minority students. <em>Science, 331</em>, 1447-1451. DOI: 10.1126/science.1198364.</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
